<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">Список скидок</h1>
    </div>
    <div class="content__party content__party--start">
      <router-link
        :to="{ name: 'partner-discounts-create' }"
        class="button button--light button--no-indent button--circle"
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить скидку</span>
      </router-link>
    </div>
    <div class="discounts-list">
      <div v-if="!discounts.length" class="list-slug">
        <img src="@/assets/img/discounts-slug.svg" alt="Slug">
        <h2 class="title title--indent title--color">Пока ничего нет</h2>
        <p>Нажмите добавить скидку, чтобы заполнить страницу</p>
      </div>
      <router-link v-else :to="{ name: 'partner-discounts-detail', params: { id: discount.id } }" v-for="discount in discounts" :key="discount.id" class="discounts-list__item">
        <img v-if="discount.crop_url" :src="discount.crop_url" alt="" class="discounts-list__img">
        <img v-else src="@/assets/img/dis-def.svg" alt="" class="discounts-list__img">
        <div class="discounts-list__info">
          <h3 class="discounts-list__title">{{ discount.title }}</h3>
          <div class="discounts-list__row">
            <b>Срок действия:</b>
            с {{ discount.date_start }} <span v-if="discount.date_end">по {{ discount.date_end }}</span>
          </div>
          <div class="discounts-list__row">
            <b>Тип:</b>
            {{ discount.type === 1 ? 'Скидка' : 'Спецпредложение' }}
          </div>
          <div class="discounts-list__row">
            <b>ID:</b>
            {{ discount.id }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Discounts',
  data() {
    return {
      discounts: [],
    };
  },
  mounted() {
    this.fetchDiscounts();
  },
  methods: {
    fetchDiscounts() {
      this.$store.dispatch('partner/discount/GET_LIST')
        .then(response => {
          this.discounts = response.data.results;
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          });
        });
    },
  }
};
</script>
